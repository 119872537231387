<template>
    <div id="inscription" class="mb-5">
        <div class="main-title">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h3><i class="bi bi-person-fill mr-3"></i>Inscription</h3>
                        <div class="underline"></div>
                    </div>
                </div>
            </div>
        </div>
        <profile-form></profile-form>
    </div>
</template>

<script>
    import ProfileForm from '@/components/front/profile/ProfileForm'
    import Vue from 'vue';
    export default {
        name: "Inscription",
        metaInfo() {
            return {
                title: 'Accueil',
                meta: [{
                    name: 'description',
                    content: 'Inscription sur la plateforme de prise de rendez-vous en ligne du '+Vue.$config.etablissement.display_name
                }]
            }
        },
        components: {
            ProfileForm
        },
    }
</script>

<style scoped>

</style>
